import { navigate } from 'gatsby';
import React from 'react';
import styles from './call-to-action.module.scss';
import useLocalStorage from '../../hooks/useLocalStorage';

/**
 * Call To Action on results page
 *
 * @returns {React.ReactElement}
 * @see resultats.js
 */
function CallToAction() {
  const scoreObject = useLocalStorage('score', null);

  function addToCart() {
    let listOptions = [];

    for (const [key, value] of Object.entries(scoreObject[0])) {
      if(value >= 20 && key !== "total"){
        listOptions.push(key)
      }
    }
    const products = listOptions.sort().join(',');
    const productList = {
      'antiox': 'antiox',
      'antiox,coaching' : 'antiox_plus',
      'coaching':'essentiel_plus',
      'coaching,poids': 'equilibre_plus',
      'poids': 'equilibre',
      'antiox,poids': 'complet',
      'antiox,coaching,poids':'absolu'  
    }

    const name = productList[products] || 'essentiel';

    navigate(`/panier/?product=${name}`);
  }

  return (
    <div className={`Center ${styles.cta}`}>
      <button onClick={addToCart} className={`Btn Btn--Black ${styles.button}`}>
        AJOUTER AU PANIER
      </button>
    </div>
  );
}

export default CallToAction;
