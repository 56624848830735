import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import styles from './cart-item.module.scss';
import CartContext from '../../context/CartContext';

function PackCartItem({ item }) {
  const { cart } = useContext(CartContext);
  const [packMonth, setPackMonth] = useState('');
  const [firstText, setFirstText] = useState('');
  const [lastText, setLastText] = useState('');
  const [price, setPrice] = useState('');

  useEffect(() => {
    switch (cart.membershipPeriod) {
      case 'monthly':
        setPackMonth('1 mois');
        setFirstText('2 SÉANCES ');
        setLastText('1 MOIS DE');
        break;
      case 'three_months':
        setPackMonth('3 mois');
        setFirstText('4 SÉANCES ');
        setLastText('3 MOIS DE ');
        setPrice('244€');
        break;
      case 'six_months':
        setPackMonth('6 mois');
        setFirstText('7 SÉANCES ');
        setLastText('6 MOIS DE');
        setPrice('488€');
        break;
      default:
        break;
    }
  }, []);

  const data = useStaticQuery(graphql`
    query PackCartItemQuery {
      pagePanierYaml {
        arrow {
          publicURL
        }
        trash {
          publicURL
        }
        star {
          publicURL
        }
        sopk {
          publicURL
        }
        age {
          publicURL
        }
      }
    }
  `);

  const images = data.pagePanierYaml;

  // Déterminer le pack et ses informations
  const isSopk = cart.contents.program === 'sopk';
  const packImage = isSopk ? images.sopk.publicURL : images.age.publicURL;
  const packLabel = isSopk ? 'Pack SOPK' : 'Pack Age';
  const packDescription = isSopk ? 'Pack SOPK ' : 'Pack Age';

  return (
    <div className={styles.wrapper}>
      {/** IMAGE */}
      <div className={styles.imageWrapper}>
        <img src={packImage} alt="produit" />
      </div>
      {/** DESCRIPTION */}
      <div className={styles.cart_description}>
        <div className={styles.label}>{packLabel}</div>

        <div className={styles.description}>
          {packDescription} {packDescription === 'Pack Age' && packMonth}
        </div>
        {}
        <div>
          <div className={styles.star}>
            <img src={images.star.publicURL} alt="star" />
            <div className={styles.star_content}>
              {packDescription === 'Pack Age' ? firstText : 'UN'} DE COACHING AVEC UNE DIÉTÉTICIENNE FERTILITÉ
            </div>
          </div>
          <div className={styles.star}>
            <img src={images.star.publicURL} alt="star" />
            <div className={styles.star_content}>DES RECETTES PROFERTILITÉ</div>
          </div>
          <div className={styles.star}>
            <img src={images.star.publicURL} alt="star" />
            <div className={styles.star_content}>
              {' '}
              {packDescription === 'Pack Age' ? lastText : ''} COMPLÉMENTS ALIMENTAIRES FABRIQUÉS EN FRANCE
            </div>
          </div>
        </div>
      </div>

      <div className={styles.price_wrapper}>
        <div>
          <span className={styles.price}>Prix Total</span>
        </div>
        <div className={styles.strikethrough_wrapper}>
          <div className={styles.price}>
            {!isSopk && price && <span className={styles.oldPrice}>{price}</span>} {cart.total}€
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

PackCartItem.propTypes = {
  packType: PropTypes.oneOf(['sopk', 'age']).isRequired,
};

export default PackCartItem;
