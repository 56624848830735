import { graphql, Link } from 'gatsby';
// Previews from prismic
import { withPreview } from 'gatsby-source-prismic';
import React, { useState, useEffect } from 'react';
import arrowUpImage from '../images/blog/Arrow_top.png';
import axios from 'axios';
import { useLocation } from '@reach/router';

// Layout
import Layout from '../components/layout';
import SEO from '../components/seo';
import { useReadingProgress } from '../hooks/useReadingProgress';
import style from '../modules/post.module.scss';
import conseilImg1 from '../images/blog/BM-1.png';
import VideoPopup from '../components/blog/video-popup';

function Page({ data, pageContext }) {
  const [activeSlide, setActiveSlide] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false); //video popup
  const completion = useReadingProgress();
  const [isFrance, setIsFrance] = useState(false);

  const hiddenPaths = [
    '/avoir-enfant-a-40-ans/',
    '/dereglement-hormonal/',
    '/aliments-fertilite/',
    '/vitamines-booster-fertilite/',
    '/courbe-temperature-grossesse/',
    '/test-ovulation/',
    '/fausses-couches/',
    '/sopk/',
    '/temps-pour-tomber-enceinte/',
    '/traitement-naturel-tomber-enceinte/',
    '/differences-pma-fiv/',
    '/endometriose-alimentation/',
  ];
  const location = useLocation();
  const hideButtonAndModal = hiddenPaths.some((path) => location.pathname.includes(path));

  // see issue #761 seo optimisation request
  const [anchorBeginning, setAnchorBeginning] = useState('Bemum vous');
  const [anchor, setAnchor] = useState('aide pour tomber enceinte');
  const [anchorEnd, setAnchorEnd] = useState('plus facilement !');

  useEffect(() => {
    // 4 fois "BeMum vous aide pour tomber enceinte plus facilement !"
    if (pageContext.index < 5) {
      setAnchorBeginning('Bemum vous');
      setAnchor('aide pour tomber enceinte');
      setAnchorEnd('plus facilement !');
    }
    // 4 fois "BeMum vous aide pour tomber enceinte rapidement !"
    if (pageContext.index >= 5 && pageContext.index < 9) {
      setAnchorBeginning('Bemum vous');
      setAnchor('aide pour tomber enceinte rapidement');
      setAnchorEnd('!');
    }
    // 4 fois "BeMum vous apporte une aide pour tomber enceinte plus facilement !"
    if (pageContext.index >= 9 && pageContext.index < 13) {
      setAnchorBeginning('Bemum vous apporte');
      setAnchor('aide pour tomber enceinte');
      setAnchorEnd('plus facilement !');
    }
    // 4 fois "BeMum vous apporte une aide pour tomber enceinte rapidement !"
    if (pageContext.index >= 13 && pageContext.index < 17) {
      setAnchorBeginning('Bemum vous apporte');
      setAnchor('une aide pour tomber enceinte rapidement');
      setAnchorEnd('!');
    }
    // 4 fois "BeMum vous donne de l'aide pour tomber enceinte plus facilement !"
    if (pageContext.index >= 17 && pageContext.index < 21) {
      setAnchorBeginning('Bemum vous donne');
      setAnchor(`de l'aide pour tomber enceinte`);
      setAnchorEnd('plus facilement !');
    }
    // 4 fois "BeMum vous donne de l'aide pour tomber enceinte rapidement !"
    if (pageContext.index >= 21 && pageContext.index < 25) {
      setAnchorBeginning('Bemum vous donne');
      setAnchor(`de l'aide pour tomber enceinte rapidement`);
      setAnchorEnd('!');
    }
    // 5 fois "Rejoignez BeMum pour avoir de l'aide pour tomber enceinte plus facilement !"
    if (pageContext.index >= 25 && pageContext.index < 30) {
      setAnchorBeginning('Rejoignez BeMum pour');
      setAnchor(`avoir de l’aide pour tomber enceinte`);
      setAnchorEnd('plus facilement !');
    }
    // 5 fois "Rejoignez BeMum pour avoir de l'aide pour tomber enceinte rapidement !"
    if (pageContext.index >= 30 && pageContext.index < 35) {
      setAnchorBeginning('Rejoignez BeMum pour');
      setAnchor(`avoir de l’aide pour tomber enceinte rapidement`);
      setAnchorEnd('!');
    }
  });

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.GATSBY_GEO_API_KEY}`);
        console.log(response);
        if (response.data && response.data.country_name) {
          const country = response.data.country_name;
          if (country === 'France') setIsFrance(true);
        }
      } catch (error) {
        console.error('', error);
        setIsFrance(true);
      }
    };

    fetchLocation();
  }, []);

  /// Desktop
  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    if (!isMobile) {
      const handleScroll = () => {
        const hasInteracted = sessionStorage.getItem('videoModal');
        const totalScrollHeight = document.body.scrollHeight - window.innerHeight;
        const scrollPosition = (window.scrollY / totalScrollHeight) * 100;

        if (scrollPosition >= 30 && !hasInteracted) {
          setModalIsOpen(true);
          window.removeEventListener('scroll', handleScroll);
        }
      };

      window.addEventListener('scroll', handleScroll);

      // Vérifie si la modal a déjà été vue, et si c'est le cas, la réaffiche lors de la prochaine visite
      const hasInteracted = sessionStorage.getItem('videoModal');
      if (hasInteracted) {
        sessionStorage.removeItem('videoModal');
      }

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const closeModal = () => {
    sessionStorage.setItem('videoModal', 'true');
    setModalIsOpen(false);
  };

  const doc = data.prismicPost || {};

  const post = doc.data || {};
  const slices = post.body || [];
  const authorDoc = post.author || {};
  const authorData = authorDoc.document || {};
  const author = authorData.data || {};

  const blogDoc = data.prismicBlog || {};
  const blog = blogDoc.data || {};
  const categoryName = post.category.document.uid;

  // const posts = data.posts || {};
  // const postNodes = posts.nodes || [];

  /** Compute 'Durée de lecture cf issue fablife/bemum#1442 */
  let nbWords = 0;
  const avgWPM = 400;
  for (const slice of slices) {
    if (slice.slice_type === 'rich_content') {
      const subWords = slice.primary.content.html.split(' ');
      nbWords += subWords.length;
    }
  }
  let timeToRead = Math.round(nbWords / avgWPM);
  if (timeToRead === 0) {
    timeToRead = 1;
  }

  /** Create an interactive summary on top of the article */
  const summary = [];
  const updatedSlices = [];
  let title = '';
  let i = 0; // slice number
  for (const slice of slices) {
    if (slice.slice_type === 'rich_content') {
      // Clone the slice
      const updatedSlice = {
        __typename: slice.__typename,
        id: slice.id,
        slice_type: slice.slice_type,
        primary: { content: { html: '' } },
      };
      let html = slice.primary.content.html;

      //
      if (i === 0) {
        let titleIndex = html.indexOf('<h2>');
        let endTitleIndex = html.indexOf('</h2>');
        if (titleIndex !== -1 && endTitleIndex !== -1) {
          title = html.substring(titleIndex, endTitleIndex + 5);
          html = html.slice(0, titleIndex) + html.slice(endTitleIndex + 5);
        }
      }

      let index = html.indexOf('<h2>');
      while (index !== -1) {
        // Create the title in the summary with a reference to the slice number
        const endIndex = html.indexOf('</h2>', index);
        const extractedTitle = html.substring(index + 4, endIndex);

        summary.push(extractedTitle);

        // Add id of slice number and a scroll margin top to manage to navbar
        html = html.slice(0, index + 3) + ` id="slice-${i}" style="scroll-margin-top: 110px;"` + html.slice(index + 3);

        i++; // A slice was found so increment the slice number

        index = html.indexOf('<h2>', index); // find the next h2
      }

      updatedSlice.primary.content.html = html;
      updatedSlices.push(updatedSlice);
    } else {
      updatedSlices.push(slice); // If slice is an image for instance, just copy it
    }
  }

  const postNodes =
    post.featured_articles.length > 0
      ? post.featured_articles.map(({ article: { document } }) => document).filter((document) => document)
      : [];

  const handleNext = () => {
    setActiveSlide((activeSlide + 1) % postNodes.length);
  };

  const handlePrev = () => {
    setActiveSlide(activeSlide === 0 ? postNodes.length - 1 : activeSlide - 1);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const openCalendly = () => {
    window.open('https://calendly.com/bemum/15min', '_blank');
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    openCalendly();
  };

  return (
    <Layout extendedReduced>
      {!hideButtonAndModal && <VideoPopup modalOpen={modalIsOpen} closeModal={closeModal} />}

      {isFrance && !hideButtonAndModal && (
        <div className={style.calendly}>
          <button onClick={openCalendly}>Prendre RDV</button>
        </div>
      )}
      <SEO
        title={post.meta_title.text}
        description={post.meta_description.text}
        canonical={`https://www.bemum.co/blog/${pageContext.uid}/`}
        appleSmartBanner
      />
      <article className={`${style.Post}`} itemScope itemType={`http://schema.org/Article`}>
        <header
          className={`BgRedLighter ${style.Header}`}
          style={{ backgroundImage: `url(${data.prismicPost.data.featured_image.url})` }}
        >
          <div className={style.menu}>
            <div className={style.container}>
              <h1 itemProp={`name`} className={`t1 mb3`}>
                {post.title.text}
              </h1>
              <div className={`Light`} dangerouslySetInnerHTML={{ __html: post.short_content.html }} />
              <ol className={style.Summary}>
                {summary.map((item, key) => (
                  <li key={key}>
                    <a href={`#slice-${key}`}>{item}</a>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </header>

        <div className={`${style.intro}`}>
          <p>
            Cet article vous est proposé par BeMum, qui développe des programmes de nutrition personnalisés, pour
            accompagner les femmes en désir d'enfant.
          </p>
        </div>

        <div style={{ width: `${completion}%` }} className={style.ProgressBar} />

        <div className={`wrapper`}>
          <div className={`cmd10 clg10 shmd1 shlg1 mb7 mbmd12`}>
            <div>
              <p className={style.motherLink}>
                {`${anchorBeginning} `}
                <a target="_blank" rel="noreferrer" href="https://www.bemum.co/">
                  {anchor}
                </a>{' '}
                {`${anchorEnd} `}
              </p>

              {/* <div className="mt4" dangerouslySetInnerHTML={{ __html: title }} /> */}
            </div>

            <div className={`${style.Content} mb5`} itemProp={`articleBody`}>
              {updatedSlices.map((slice, key) => (
                <div>
                  <Slice key={`slice-${key}`} uid={pageContext.uid} {...slice} />
                </div>
              ))}
            </div>

            {(categoryName === 'ovulation' || hideButtonAndModal) && (
              <div className={style.conseilContainer}>
                <div className={style.conseil}>
                  <div className={style.conseilContent}>
                    <h2> Qui sommes nous ?</h2>
                    <p>
                      Développé par des scientifiques, des médecins et des nutritionnistes, Bemum est un programme de
                      nutrition personnalisée, au service des femmes qui veulent améliorer leur fertilité et tomber
                      enceinte plus rapidement.
                    </p>

                    <p>
                      Faites notre test Fertilité et découvrez votre programme sur-mesure choisi par nos experts parmi
                      nos 8 programmes fertilité.
                    </p>
                    <Link to="/test-fertilite" className="Btn Btn--Black">
                      Faire mon test de fertilité
                    </Link>
                  </div>

                  <img src={conseilImg1} alt="" />
                </div>
              </div>
            )}

            {postNodes.length > 0 && (
              <div className={style.other_articles}>
                <div>
                  <h2 className={`ml5`}>{blog.other_articles && blog.other_articles.text}</h2>
                </div>

                <div className={`${style.other_articleContainer}`}>
                  <Link className={style.other_article} to={`/blog/${postNodes[activeSlide].uid}/`}>
                    <div>
                      <img
                        src={postNodes[activeSlide].data.featured_image.url}
                        alt={postNodes[activeSlide].data.title.text}
                        itemProp={`image`}
                      />
                    </div>
                  </Link>
                  {postNodes.length > 1 && (
                    <>
                      <button className={style.sliderButton} onClick={handlePrev}></button>
                      <button className={`${style.sliderButton} ${style.next}`} onClick={handleNext}></button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </article>
      <div className={style.scrollToTopButton} onClick={scrollToTop}>
        <img src={arrowUpImage} alt="Flèche vers le haut" />
      </div>
    </Layout>
  );
}

/**
 * Slice: Rich HTML content
 */
const RichContent = ({ primary }) => {
  return <div dangerouslySetInnerHTML={{ __html: primary.content.html }} />;
};

const FullImage = ({ uid, primary }) => {
  const alt = primary.image.alt || uid;
  return (
    <div className={`${style.Image}`}>
      <img src={primary.image.url} alt={alt} />
    </div>
  );
};

const components = {
  rich_content: RichContent,
  full_image: FullImage,
};

const Slice = ({ uid, slice_type, ...slice }) => {
  const Component = components[slice_type];
  if (Component) return <Component uid={uid} {...slice} />;
  return null;
};

export const query = graphql`
  query($lang: String, $uid: String) {
    posts: allPrismicPost(
      sort: { fields: data___date_published, order: DESC }
      filter: { lang: { eq: $lang }, uid: { ne: $uid } }
      limit: 3
    ) {
      nodes {
        ...PostShort
      }
    }
    prismicBlog(lang: { eq: $lang }) {
      data {
        other_articles {
          text
        }
      }
    }
    prismicPost(lang: { eq: $lang }, uid: { eq: $uid }) {
      data {
        date_published(formatString: "DD MMMM Y", locale: $lang)
        featured_image {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
          alt
          url
        }
        featured_articles {
          article {
            document {
              ... on PrismicPost {
                ...PostShort
              }
            }
          }
        }
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                name {
                  text
                }
                photo {
                  url
                  fluid {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
              }
            }
          }
        }
        category {
          document {
            ... on PrismicCategory {
              id
              data {
                name {
                  text
                }
              }
              uid
            }
          }
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        short_content {
          html
          text
        }
        title {
          text
        }
        body {
          ... on PrismicPostBodyFullImage {
            id
            primary {
              image {
                fluid {
                  base64
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
                alt
                url
              }
            }
            slice_type
          }
          ... on PrismicPostBodyRichContent {
            id
            slice_type
            primary {
              content {
                html
              }
            }
          }
        }
      }
    }
  }
`;

export default withPreview(Page);
